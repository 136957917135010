import React from 'react';
import Head from 'next/head'
import { APP_CONFIG } from '../../Config';

const Schema = () => {
    const schemaData = {
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "StorageTreasures",
        "alternateName": "StorageTreasures.com",
        "url": APP_CONFIG.APP_URL,
        "logo": `${APP_CONFIG.APP_URL}/static/images/STLogoLarge.svg`,
        "contactPoint": {
            "@type": "ContactPoint",
            "telephone": "+1 (480) 397-6503",
            "contactType": "customer service",
            "areaServed": ["US","CA"],
            "availableLanguage": "en"
        },
        "address": {
            "@type": "PostalAddress",
            "streetAddress": "2101 W Peoria Ave Unit 100",
            "addressLocality": "Phoenix",
            "addressRegion": "AZ",
            "postalCode": "85029",
            "addressCountry": "US"
        },
        "sameAs": [
            "https://www.facebook.com/storagetreasures",
            "https://www.twitter.com/storagetreasure",
            "https://www.instagram.com/storagetreasures",
            "https://www.linked.com/company/storage-treasures-inc.",
            "https://www.youtube.com/user/storagetreasures"
        ]
    }


    return (
        <Head>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
            />
        </Head>
    );
};

export default Schema;
