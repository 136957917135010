import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import Loading from 'components/Loading'
import { nl2br } from 'public/static/js/Utils'
import Carousel from 'components/partials/Carousel'
import Modal from './Modal'
import 'styles/components/must-know.less'

const MustKnow = ({ notification_loading, notifications = [], images }) => {
    const [activeSlide, setActiveSlide] = useState(0)
    const [image, setImage] = useState('')

    useEffect(() => {
        const getImage = () => {
            const innerWidth = typeof window !== 'undefined' && window.innerWidth

            if (innerWidth < 320) {
                setImage(images[0]) // xxs
            } else if (innerWidth < 480) {
                setImage(images[1]) // xs
            } else if (innerWidth < 768) {
                setImage(images[2]) // sm
            } else if (innerWidth < 992) {
                setImage(images[3]) // md
            } else {
                setImage(images[4]) // lg
            }
        }

        getImage()
        window.addEventListener('resize', getImage)

        return () => {
            window.removeEventListener('resize', getImage)
        }
    }, [images])

    const renderModal = () => {
        if (notification_loading) {
            return (
                <Loading loading_component={[{ label: 'Loading', loading: notification_loading }]} />
            )
        }

        return (
            <Modal
                name="learn-more"
                hasClose
                header="Stay in the Know"
                modalSize="lg"
                modal_container_class="stay-in-the-know-modal"
            >
                <Carousel
                    name="must-know-carousel"
                    height="45vh"
                    accentColor="primary"
                    timeout={false}
                    initialSlide={activeSlide}
                    controlsColor="#0F7DBA"
                    class="sitk-modal-carousel"
                >
                    {notifications.map((item, index) => (
                        <div key={index} className="vertical-align-container">
                            <div className="must-know-modal-content">
                                <div className="must-know-modal-title">
                                    {item.subject}
                                </div>
                                <div className="must-know-modal-body" dangerouslySetInnerHTML={{ __html: nl2br(item.body) }} />
                            </div>
                        </div>
                    ))}
                </Carousel>
            </Modal>
        )
    }

    if (notification_loading) {
        return (
            <Loading loading_component={[{ label: 'Loading', loading: notification_loading }]} />
        )
    }

    if (notifications.length === 0) {
        return null
    }

    return (
        <div className="must-know home-hero">
            <div className="must-know-background must-know-bg-image" />
            <div className="container">
                <div className="must-know-image-container must-know-bg-image">
                    <img src={image} alt="Must Know" className="must-know-image" />
                </div>
            </div>
            <div className="carousel-content-parent text-center">
                <div className="carousel-content">
                    <Carousel name="homepage-must-know-carousel" height="90%" accentColor="white" timeout="5000">
                        {notifications.map(({ subject, excerpt }, index) => (
                            <div key={index} className="item-body">
                                <div className="must-know-title">
                                    {subject}
                                </div>
                                <div className="must-know-body">
                                    {excerpt}
                                </div>
                                <div className="must-know-action">
                                    <button
                                        data-toggle="modal"
                                        data-target="#learn-more"
                                        className="btn btn-orange btn-block btn-regular"
                                        onClick={() => setActiveSlide(index)}
                                    >
                                        Learn More
                                    </button>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </div>
            {renderModal()}
        </div>
    )
}

const mapStateToProps = () => ({})

export default connect(mapStateToProps)(MustKnow)