import './styles.less'
import SMSOptInAlert from '../SMSOptInAlert'
import UserStatusNotification from '../UserStatusNotification'
import { Row, Col } from 'react-bootstrap'
import React from 'react'
const phones = '/static/images/home/app-promo/phones.png'
const apple_store_icon = '/static/images/home/app-promo/apple-store-icon.png'
const google_play_icon = '/static/images/home/app-promo/google-play-icon.png'

const AppPromoHero = ({children}) => {
    return (
        <div className="hero home-hero app-promo-hero">
            <div className="app-promo-hero-container">
                <SMSOptInAlert />
                <UserStatusNotification />

                <br/>
                <Row className="marginT20">
                    <Col md={7}>
                        <div className="sub-container app-promo-sub-container">
                            <h1 className="app-promo-hero-title">Find Online Storage Auctions</h1>
                            <h2 className="app-promo-hero-subtitle">with the most complete source of storage auctions near you</h2>
                            <div className="app-promo-hero-children">{children}</div>
                        </div>
                    </Col>
                    <Col mdOffset={1} md={4} className="app-promo-hero-image-container">
                        <img src={phones} className="app-promo-hero-image" />
                        <div className="app-promo-hero-mobile-promo-container">
                            <h3 className="app-promo-hero-image-subtitle">
                                For enhanced on the go bidding, download our app
                                and get real-time bid notifications!
                            </h3>
                            <div className="app-promo-hero-stores">
                                <a href="https://play.google.com/store/apps/details?id=com.opentech.storagetreasures" target="_blank">
                                    <img src={google_play_icon} alt=""/>
                                </a>
                                <a href="https://apps.apple.com/us/app/storagetreasures-auction-app/id6448074082" target="_blank">
                                    <img src={apple_store_icon} alt=""/>
                                </a>
                            </div>
                        </div>

                    </Col>
                </Row>
            </div>
        </div>
    )
}

export default AppPromoHero
