import React from 'react';
import Head from 'next/head'
import { APP_CONFIG } from '../../Config';

const Schema = () => {
    const schemaData = {
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": "StorageTreasures",
        "url": APP_CONFIG.APP_URL,
        "potentialAction": {
            "@type": "SearchAction",
            "target": {
                "@type": "EntryPoint",
                "urlTemplate": APP_CONFIG.APP_URL + '/auctions?type=keyword&filter_types=1&filter_types=2&filter_types=3&term={search_term_string}',
            },
            "query-input": "required name=search_term_string"
        }
    }

    return (
        <Head>
            <script
                type="application/ld+json"
                dangerouslySetInnerHTML={{ __html: JSON.stringify(schemaData) }}
            />
        </Head>
    );
};

export default Schema;
