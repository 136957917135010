import React, { Component } from 'react';
import loadScript from './ScriptLoader';

class TrustPilot extends Component {
    componentDidMount = () => {
        if ((typeof window !== 'undefined' && !window.Trustpilot) || (!typeof window != 'undefined' && typeof window.Trustpilot.Modules === 'undefined')) {
            this.loadTPScript();
        } else {
            const trustBox = document.getElementById('trustpilot-widget');
            typeof window !== 'undefined' && window.Trustpilot.loadFromElement(trustBox);
        }
    };

    loadTPScript = () => {
        loadScript('https://widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js').then((script) => {
            this.tpScript = script;
        })
            .catch((err) => {
                console.error(err.message);
            });
    };

    render() {
        const {
            businessunit_id,
            template_id,
            height,
            reviewnumber,
        } = this.props;

        if (!template_id) return null;

        let _reviewNumber = reviewnumber;

        if (typeof reviewnumber !== 'number' || !reviewnumber) {
            _reviewNumber = reviewnumber ? 10 : 6;
        }

        return (
          <div className="paddingT20">
            { /* TrustBox widget - Quote */}
            <div
              id="trustpilot-widget"
              className="trustpilot-widget"
              data-locale="en-US"
              data-template-id={template_id}
              data-businessunit-id={businessunit_id}
              data-reviewnumber={_reviewNumber}
              data-style-height={height}
              data-style-width="100%"
              data-theme="light"
              data-stars="4,5"
            >

              <a href="https://www.trustpilot.com/review/storagetreasures.com" target="_blank" rel="noopener noreferrer">Trustpilot</a>
            </div>
            { /* End TrustBox widget */}
          </div>
        );
    }
}

TrustPilot.defaultProps = {
    // template_id: '54d0e1d8764ea9078c79e6ee', // original value
    businessunit_id: '595674440000ff0005a5af3f',
    template_id: false,
    height: '1000px',
    reviewnumber: false,
};

export default TrustPilot;
