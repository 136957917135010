import React, { Component } from 'react'
import { get } from 'lodash'
import 'styles/components/Modal.less'

class Modal extends Component {
    componentDidMount() {
        const {
            defaultOpen,
            name,
            onClose
        } = this.props

        if (defaultOpen === true) {
            typeof window !== 'undefined' && window.jQuery(`#${name}`).modal('show')
        }

        if (onClose) {
            window.$(`#${this.props.name}`).on('hidden.bs.modal', () => {
                // add component close callback
                onClose()
            })
        }
    }

    renderHeader = () => {
        let close = null

        if (get(this.props, 'hasClose', false)) {
            close = (
                <button 
                    id="modal-close" 
                    type="button" 
                    className="close" 
                    data-dismiss="modal"
                    onClick={this.props.onClose}
                >
                        &times;
                </button>
            )
        }

        let header_text = get(this.props, 'header')
        if (header_text) {
            header_text = (<h4 className="modal-title">{header_text}</h4>)
        }

        if (!close && !header_text) {
            return null
        }

        return (
            <div className="modal-header">
                {close}
                {header_text}
            </div>
        )
    }

    renderFooter = () => {
        const footer = get(this.props, 'footer')

        if (footer) {
            return (
                <div className="modal-footer">
                    {footer}
                </div>
            )
        }

        return footer
    }

    render() {
        const { modalSize, modal_container_class } = this.props
        
        return (
            <div id={this.props.name} className={`modal fade ${modal_container_class}`} role="dialog">
                <div className={`modal-dialog modal-${modalSize}`}>
                    <div className="modal-content">
                        {this.renderHeader()}
                        <div className={`modal-body ${this.props.bodyClassName}`}>
                            {this.props.children}
                        </div>
                        {this.renderFooter()}
                    </div>
                </div>
            </div>
        )
    }
}

Modal.defaultProps = {
    name: 'myModal',
    onClose: undefined,
    defaultOpen: false,
    modalSize: 'custom',
    modal_container_class: '',
    bodyClassName: ''
}

export default Modal